<template>
    <ui-create
        name="providers"
        :title="title"
        :id="id"
    >
        <template #body >
            <ui-crud
                v-model="form"
                :rows="rows"
            />
        </template>
    </ui-create>
</template>
<script>
import uiCreate from '@/templates/create'
import {uiCrud} from '@/components/ui'
export default {
    name: 'vwProvidersCreate',
    components: {
        uiCreate,
        uiCrud
    },
    data(){
        return {
            load: false,
            form: {
                nome: '',
                razaosocial: null,
                cnpj: '',
                inscricao: '',
                email: '',
                telefone: '',
                fornecedor_atividade: null,
                fornecedor_tipo_atividade: null,
                fornecedor_margem_adicional: null,
                fornecedor_transportadora: null,
                tipo_pessoa: 'FOR'
            },

            rows: [
                [
                    {
                        name: 'nome',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Nome da Empresa',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: 6
                        }
                    },
                    {
                        name: 'razaosocial',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Razão Social',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: 6
                        }
                    },
                ], [
                    {
                        name: 'cnpj',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'CNPJ',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 8,
                            lg: 8,
                            xl: 8
                        }
                    },
                    {
                        name: 'inscricao',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Inscrição',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 4,
                            xl: 4
                        }
                    },
                ], [
                    {
                        name: 'fornecedor_atividade',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'Atividade',
                        handle: 'default',
                        options: ['Auto Peças', 'Auto Elétrica'],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 6,
                            lg: 6,
                            xl: 6
                        }
                    },
                    {
                        name: 'fornecedor_tipo_atividade',
                        value: '',
                        type: 'string',
                        field: 'autocomplete',
                        label: 'Tipo de Atividade',
                        handle: 'default',
                        options: ['Agricola', 'Comercio', 'Industria'],
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 4,
                            lg: 4,
                            xl: 4
                        }
                    },
                    {
                        name: 'fornecedor_margem_adicional',
                        value: '',
                        type: 'string',
                        field: 'text-field',
                        label: 'Margem Adicional',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 2,
                            lg: 2,
                            xl: 2
                        }
                    }
                ], [
                    {
                        name: 'fornecedor_margem_adicional',
                        value: '',
                        type: 'string',
                        field: 'checkbox',
                        label: 'Esse fornecedore é uma transportadora?',
                        handle: 'default',
                        col: {
                            default: 12,
                            xs: 12,
                            sm: 12,
                            md: 12,
                            lg: 12,
                            xl: 12
                        }
                    }
                ],
            ],
        }
    },

    computed:{
        id(){
            return this.$route.params.id || 0
        },

        title(){
            return this.id ? `Editar fornecedor: ${this.id}` : 'Cadastrar Fornecedor'
        }
    },

    mounted(){
        this.$root.$on('load-data-providers', (data) => {
            this.form = data
        })
    }
}
</script>